<template>
  <div id="app">
    <Nav />
    <router-view class="body"></router-view>
    <Footer />
  </div>
</template>

<script>
import Nav from './components/Nav.vue';
import Footer from './components/Footer.vue';

export default {
  components: {
    Nav,
    Footer,
  },
};
</script>

<style>
body {
  margin: 0px;
  background-color: white;
  font-size: 1.1em;
  line-height: 1.6em;
}
#app {
  font-family: Aileron, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  min-height: 100vh;
}
.body {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 90vh;
  position: relative;
  top: 73px;
  box-sizing: border-box;
  padding-bottom: 75px;
}
h3 {
  margin: 0;
  font-size: 1.5em;
}
.section {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.bold {
  font-weight: bold;
  display: inline;
}
.button {
  display: inline-block;
  padding: 0.4em 0.6em;
  cursor: pointer;
  /* background-color: #8b8c4a; */
  background-color: #f5f7ec;
  border-radius: 25px;
  font-weight: 600;
  /* color: white; */
  color: black;
  transition: 0.2s;
  border: none;
  font-size: 1em;
}
/* .button:hover {
  background-color: #dde0d0;
  color: #8b8c4a;
} */
.divider {
  width: 100%;
  border-bottom: 1px solid #dde0d0;
  margin: 1em 0 2em 0;
}
.banner {
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  position: relative;
  background-size: cover;
  background-position-y: center;
  background-color: gray;
}
.banner__overlay {
  height: 100%;
  width: 50%;
  position: absolute;
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.9),
    rgba(255, 255, 255, 0.9),
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0)
  );
}
.banner h1 {
  z-index: 3;
  font-weight: 300;
  font-size: 5em;
  margin: 0 0 0 1em;
}
</style>
