<template>
  <div id="nav">
    <div :href="link" v-on:click="methods.scrollToTop" alt="nav-logo">
      <div class="logo" alt="nav-logo-img" />
    </div>
    <nav>
      <router-link to="/" v-on:click="methods.scrollToTop">Home</router-link>
      <router-link to="/about" v-on:click="methods.scrollToTop"
        >About
      </router-link>
      <router-link to="/careers" v-on:click="methods.scrollToTop"
        >Careers
      </router-link>
      <router-link to="/contact-us" v-on:click="methods.scrollToTop"
        >Contact Us
      </router-link>
    </nav>
  </div>
</template>

<script>
import { Vue } from 'vue-class-component';
import logoWhiteOutline from '../assets/logoWhiteOutline.png';

export default class Nav extends Vue {
  img = logoWhiteOutline;
  link = '/';

  methods = {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  };
}
</script>

<style scoped>
#nav {
  background-color: #21372b;
  box-sizing: border-box;
  height: 75px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;

  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 5000;
  box-shadow: 1px 1px 11px rgba(0, 0, 0, 0.1);
}
.logo {
  height: 65px;
  width: 350px;
  background-image: url('~@/assets/logoWhiteOutline.png');
  background-repeat: no-repeat;
  background-size: contain;
}

nav a {
  color: white;
  font-weight: 600;
  font-size: 1.1em;
  text-decoration: none;
  padding: 0px 8px;
  margin: 0 1em;
  transition: 0.2s;
  display: inline-block;
  white-space: nowrap;
}
nav a:hover {
  color: #dde0d0;
}
@media only screen and (max-width: 900px) {
  .logo {
    width: 100px;
    background-image: url('~@/assets/shieldLogoWhiteOutline.png');
    background-repeat: no-repeat;
    background-size: contain;
  }
  nav a {
    margin: 0;
    /* font-size: 1em; */
    font-size: 1em;
  }
}
@media only screen and (max-width: 640px) {
  .logo {
    /* width: 100px; */
    height: 50px;
    width: 50px;
    background-image: url('~@/assets/shieldLogoWhiteOutline.png');
    background-repeat: no-repeat;
    background-size: contain;
  }
  nav {
    display: flex;
    width: 100vw;
    justify-content: flex-end;
    align-items: center;
  }
  nav a {
    margin: 0;
    font-size: 0.7em;
    /* display: flex; */

    /* align-items: center; */
    color: white;
    padding: 0 0.5em;
  }
}
</style>
