<template>
  <div id="footer">
    <div>562 Independence Road, East Stroudsburg, PA 18301</div>
    <div>© 2023 FD Software Enterprises</div>
  </div>
</template>

<script>
import { Vue } from 'vue-class-component';

export default class Footer extends Vue {}
</script>

<style scoped>
h3 {
  margin: 40px 0 0;
}
#footer {
  box-sizing: border-box;
  padding: 10px;
  background-color: #21372b;
  color: white;
  height: 100px;
  position: absolute;
  width: 100%;
}
</style>
